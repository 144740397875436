import { render, staticRenderFns } from "./CustomerSupportChatBot.vue?vue&type=template&id=f1fb2a36"
import script from "./CustomerSupportChatBot.vue?vue&type=script&lang=js"
export * from "./CustomerSupportChatBot.vue?vue&type=script&lang=js"
import style0 from "./CustomerSupportChatBot.vue?vue&type=style&index=0&id=f1fb2a36&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports